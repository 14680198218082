export interface ApplyAutoSizeToThemeSchemaParams<TSchema, TConfig> {
  schema: Partial<TSchema>;
  config?: Partial<TConfig>;
  configPropName?: string;
}

export const applyAutoSizeToThemeSchema = <TSchema, TConfig>({
  schema,
  config,
  configPropName = 'autoSize',
}: ApplyAutoSizeToThemeSchemaParams<TSchema, TConfig>) => {
  return {
    ...schema,
    [configPropName]: config,
  };
};
