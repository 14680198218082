import { ThemeAutoSize } from './types';

import { mergeMediaQueries } from '@ui/base-theme-auto-size';
import { autoCommonFontSize, autoCommonLineHeight } from './font';
import { indent } from './indent';
import { size } from './size';

export const autoSize: ThemeAutoSize = {
  merge: mergeMediaQueries,
  ...indent,
  ...size,
  fontSize: autoCommonFontSize,
  lineHeight: autoCommonLineHeight,
};
