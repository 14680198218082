// import { addHexAlpha } from 'plugins/styles';
import { common } from './common';
import { ThemeSchema } from '../types';

// Palettes
import { palette as basePalette } from '../palettes/palette-base';
import { palette as pinkPalette } from '../palettes/palette-pink';
import { palette as darkgreenPalette } from '../palettes/palette-darkgreen';

// Extends from common theme settings
export const light: ThemeSchema = {
  ...common,
  color: {
    primary: '#194264',
    secondary: '#D0A169',
    secondary25: '#FDF7F0',
    secondary50: '#EDEAE6',
    secondary100: '#F5DEBF',
    secondary200: '#BFB6A4',
    secondary300: '#A69A83',
    secondary400: '#8D7E62',
    secondary500: '#736342',
    secondary600: '#5A4C2A',
    secondary650: '#4F431F',
    secondary700: '#443916',
    secondary800: '#392F0C',
    secondary900: '#2E2503',

    primary50: '#EEF4FC',
    primary100: '#D9EDF8',
    primary200: '#B0D7EC',
    primary300: '#93C2E8',
    primary400: '#6DADE0',
    primary500: '#4D97D9',
    primary600: '#3C7BBF',
    primary650: '#2F6AA9',
    primary700: '#2B5FA5',
    primary800: '#1F4F8D',
    primary900: '#194264',

    neutral50: '#F8F8F9',
    neutral100: '#E9EBED',
    neutral200: '#D2D6DB',
    neutral300: '#BCC2C9',
    neutral400: '#A5ADB7',
    neutral500: '#6B7785',
    neutral600: '#505964',
    neutral700: '#353B43',
    neutral800: '#191D23',
    neutral900: '#0D0F11',

    success50: '#ECFDF5',
    success100: '#D1FAE5',
    success200: '#A7F3D0',
    success300: '#6EE7B7',
    success400: '#34D399',
    success500: '#10B981',
    success600: '#059669',
    success700: '#047857',
    success800: '#065F46',
    success900: '#064E3B',

    warning50: '#FFFBEB',
    warning100: '#FEF3C7',
    warning200: '#FDE68A',
    warning300: '#FCD34D',
    warning400: '#FBBF24',
    warning500: '#F59E0B',
    warning600: '#D97706',
    warning700: '#B45309',
    warning800: '#92400E',
    warning900: '#78350F',

    error50: '#FEF2F2',
    error100: '#FEE2E2',
    error200: '#FECACA',
    error300: '#FCA5A5',
    error400: '#F87171',
    error500: '#EF4444',
    error600: '#DC2626',
    error700: '#B91C1C',
    error800: '#991B1B',
    error900: '#7F1D1D',

    shade0: '#ffffff',
    shade100: '#00000',
    white: '#ffffff',
    black: '#000000',
  },
  boxShadow: {
    primary: '0 0 2px 4px #B0D7EC',
    secondary: '0 0 0 4px #D0A169',
  },
  border: {
    primary: `1px solid #194264`,
  },
  gradient: {
    primary: ['#007dd7', '#0faff3'],
    secondary: ['#f76b1c', '#ff9900'],
  },
  palettes: {
    base: basePalette,
    pink: pinkPalette,
    darkgreen: darkgreenPalette,
  },
};

export default light;
