import React from 'react';

// Import styled components
import _styled, { css as CSS, DefaultTheme, useTheme as UT, withTheme as WT, keyframes as KS, ThemeProvider as TP, isStyledComponent as ISC, createGlobalStyle as CGS, RuleSet } from 'styled-components';

// Re export types
export type { DefaultTheme, RuleSet };

// Theme settings
export type ThemeProviderProps = Parameters<typeof TP>[0];
const ThemeProvider = ({
  theme,
  children
}: ThemeProviderProps) => {
  return <TP theme={theme}>{children}</TP>;
};

// Default export
export const init: any = () => {
  return {
    styled: _styled,
    css: CSS,
    // Apply constants and hooks
    useTheme: UT,
    applyTheme: WT,
    keyframes: KS,
    applyCustomCSS: CSS,
    createGlobalStyle: CGS,
    applyCheckIsStyledComponent: ISC,
    applyThemeProvider: () => ThemeProvider
  };
};