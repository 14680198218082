export const palette = {
  primary: '#ffffff',
  secondary: '#ffffff',
  background: '#ffffff',
  text: '#ffffff',
  hintsText: '#ffffff',
  placeholder: '#ffffff',
  title: '#ffffff',
  info: '#ffffff',
  error: '#ffffff',
  warning: '#ffffff',
  success: '#ffffff',
};
