import { createMediaGenerator } from '@ui/base-theme-auto-size';
import { HeightList } from '../../types';

const createContentHeightMediaGenerator = (cssProp: string) =>
  createMediaGenerator<HeightList>({
    themeProp: 'height',
    cssProp,
    settings: {
      _defaults: {
        mobileL: 0.5,
        tablet: 0.7,
      },
    },
  });

export const autoHeight = createContentHeightMediaGenerator('height');
export const autoMinHeight = createContentHeightMediaGenerator('min-height');
export const autoMaxHeight = createContentHeightMediaGenerator('max-height');
export const autoWidth = createContentHeightMediaGenerator('width');
export const autoMaxWidth = createContentHeightMediaGenerator('max-width');
export const autoMinWidth = createContentHeightMediaGenerator('min-width');

export const size = {
  height: autoHeight,
  width: autoWidth,
  minHeight: autoMinHeight,
  minWidth: autoMinWidth,
  maxHeight: autoMaxHeight,
  maxWidth: autoMaxWidth,
};
