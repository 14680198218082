// import { addHexAlpha } from 'plugins/styles';
import { common } from './common';
import { ThemeSchema } from '../types';

// Extends from common theme settings
export const light: ThemeSchema = {
  ...common,
  color: {
    primary: '#304FFD',
    primary100: '#EEF4FC',
    primary200: '#D9EDF8',
    primary300: '#B0D7EC',
    primary400: '#93C2E8',
    primary500: '#6DADE0',
    primary600: '#4D97D9',
    primary700: '#3C7BBF',
    primary800: '#304FFD',
    primary900: '#1F3F9A',

    secondary: '#FF965D',
    secondary100: '#fff7d6',
    secondary200: '#ffe5ad',
    secondary300: '#ffd484',
    secondary400: '#ffc25b',
    secondary500: '#ffaf32',
    secondary600: '#ff9d09',
    secondary700: '#e68c00',
    secondary800: '#FF965D',
    secondary900: '#b26900',

    yellow: '#FFD240',
    yellow100: '#FFF8E5',
    yellow200: '#FFEFCB',
    yellow300: '#FFE6B2',
    yellow400: '#FFDD98',
    yellow500: '#FFD240',
    yellow600: '#FFC600',
    yellow700: '#E6AD00',
    yellow800: '#CC9400',
    yellow900: '#B27B00',

    error: '#FD7972',
    error100: '#FFF2F2',
    error200: '#FFD6D6',
    error300: '#FFB9B9',
    error400: '#FF9D9D',
    error500: '#FD7972',
    error600: '#FF5E5E',
    error700: '#FF4242',
    error800: '#FF2626',
    error900: '#FF0A0A',

    pink: '#FD72DE',
    pink100: '#FFF2F7',
    pink200: '#F9DDC5',
    pink300: '#FFB8DF',
    pink400: '#FF9BD3',
    pink500: '#FD72DE',
    pink600: '#FF4EBF',
    pink700: '#FF2A9F',
    pink800: '#FF0680',
    pink900: '#D9005E',

    success: '#49C96D',
    success100: '#E6F5E7',
    success200: '#AEE553',
    success300: '#9EDFB1',
    success400: '#7AD596',
    success500: '#49C96D',
    success600: '#2E9F53',
    success700: '#1F7A3E',
    success800: '#105529',
    success900: '#003214',

    blue: '#22CCE2',
    blue100: '#E9F3F5',
    blue200: '#AEEFF5',
    blue300: '#9ED2DF',
    blue400: '#7ABFD5',
    blue500: '#49ADC9',
    blue600: '#2E8EA0',
    blue700: '#1F6B7A',
    blue800: '#104D55',
    blue900: '#00332F',

    purple: '#B656EB',
    purple100: '#F2E6F9',
    purple200: '#D5AEEF',
    purple300: '#C49ED2',
    purple400: '#A77ABF',
    purple500: '#8A49AD',
    purple600: '#6B2E8E',
    purple700: '#4F1F6B',
    purple800: '#33104D',
    purple900: '#1A0033',

    dark: '#1E1F2D',
    dark400: '#595F69',
    dark500: '#3F434A',

    grey: '#8A9099',
    grey40: '#F9F9F9',
    grey50: '#F8F8F8',
    grey60: '#E6E6E6',
    grey100: '#E8E9EB',
    grey200: '#D8D8D8',
    grey250: '#C1D1E5',
    grey300: '#C6C9CC',

    white: '#FFFFFF',
    black: '#000000',
  },
  boxShadow: {
    primary: '0 0 2px 4px #B0D7EC',
    secondary: '0 0 0 4px #D0A169',
  },
  border: {
    primary: `1px solid #194264`,
  },
  gradient: {
    primary: ['#007dd7', '#0faff3'],
    secondary: ['#f76b1c', '#ff9900'],
  },
};

export default light;
