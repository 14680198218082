import { ThemeFontSize } from '../../types';

// Utils
import {
  createMediaGenerator,
  MediaGeneratorSettings,
} from '@ui/base-theme-auto-size';

export const fontSizeReducePercent: MediaGeneratorSettings<
  keyof ThemeFontSize
> = {
  _defaults: {
    mobileL: 0.7,
    tablet: 0.8,
  },
};

export const createFontMediaGenerator = (
  cssProp: Array<string> | string = 'font-size',
) =>
  createMediaGenerator<keyof ThemeFontSize>({
    themeProp: 'fontSize',
    settings: fontSizeReducePercent,
    cssProp,
    minSize: 'xs',
  });

export const autoCommonFontSize = createFontMediaGenerator();

export const autoCommonLineHeight = createFontMediaGenerator('line-height');
