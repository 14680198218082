import { css, useTheme } from 'styled-components';
import { BreakpointList, ThemeSchema } from '@ui/base-theme-schemas/src/types';
import { breakpoint } from '@ui/base-theme-schemas/src/schemas/breakpoint';

export type MediaGeneratorSettings<T extends string> = Partial<
  Record<T | '_defaults', Partial<Record<BreakpointList, number | T>>>
>;

export interface CreateMediaGeneratorParams<SizeKeys extends string> {
  themeProp: keyof ThemeSchema;
  cssProp: Array<string> | string;
  settings: MediaGeneratorSettings<SizeKeys>;
  minSize?: number | SizeKeys;
  maxSize?: number | SizeKeys;
  allowScale?: boolean;
  unit?: 'px' | 'rem' | 'em' | '%' | string;
}

export interface MediaGeneratorParams<SizeKeys extends string>
  extends Partial<Omit<CreateMediaGeneratorParams<SizeKeys>, 'settings'>> {
  log?: boolean;
  outputFormat?: 'css' | 'obj';
}

export interface MediaGeneratorOutputObject {
  stylesContext: string | any;
  baseStyles: Array<string> | string;
  mediaQueries: Record<string, Array<string> | string>;
}

export const createMediaGenerator = <T extends string>({
  themeProp: topLevelThemeProp,
  settings,
  cssProp: topLevelCssProp,
  minSize: topLevelMinSize,
  maxSize: topLevelMaxSize,
  allowScale: topLevelAllowScale = false,
  unit: topLevelUnit = 'px',
}: CreateMediaGeneratorParams<T>) => {
  const cache: Record<string, any> = {};

  return (
    size: T | number | Array<T | number>,
    {
      themeProp = topLevelThemeProp,
      cssProp = topLevelCssProp,
      minSize = topLevelMinSize,
      maxSize = topLevelMaxSize,
      allowScale = topLevelAllowScale,
      unit = topLevelUnit,
      outputFormat = 'css',
      log = false,
    }: MediaGeneratorParams<T> = {}
  ) => {
    // Get theme from context
    const theme = useTheme();

    const sizeArr = Array.isArray(size) ? size : [size];
    const cssPropArr = Array.isArray(cssProp) ? cssProp : [cssProp];
    const sizeArrStr = sizeArr.join('-');
    const cssPropStr = cssPropArr.join('-');

    // Create cache key
    const cacheKey = `${sizeArrStr}-${themeProp}-${cssPropStr}-${minSize}-${maxSize}-${allowScale}-${unit}`;

    // Use cache to avoid unnecessary calculations
    if (cache[cacheKey]) {
      if (outputFormat === 'css') {
        return cache[cacheKey][0];
      }
      return cache[cacheKey][1];
    }

    // It'll be used if outputFormat === 'obj', meant to mergeMediaQueries function
    const outputObj: MediaGeneratorOutputObject = {
      stylesContext: '',
      baseStyles: '',
      mediaQueries: {},
    };

    // Get breakpoints from theme
    const { media = {} } = theme || {};

    // Get styles from theme
    const { [themeProp]: themePropValue = {} } = theme || {};

    // Get min and max size numbers from props or from theme
    const minSizeNumber =
      parseFloat(minSize as string) ||
      parseFloat(themePropValue[minSize]) ||
      -Infinity;
    const maxSizeNumber =
      parseFloat(maxSize as string) ||
      parseFloat(themePropValue[maxSize]) ||
      Infinity;

    const settingsBreakpointArr = sizeArr.map((size) => {
      return settings[size as T] || settings._defaults || {};
    });

    const filteredSettingsBreakpointArr = Object.entries(breakpoint)
      .reduce((accum, [breakpointName, breakpointSize]) => {
        const output = [breakpointName, breakpointSize];

        const temp: Array<T | number> = [];

        settingsBreakpointArr.forEach((settingsBreakpoint) => {
          if (settingsBreakpoint[breakpointName]) {
            temp.push(settingsBreakpoint[breakpointName]);
          }
        });

        if (temp.length === settingsBreakpointArr.length) {
          output.push(...temp);
          accum.push(output as any);
        }

        return accum;
      }, [] as Array<[string, number, ...T[]]>)
      .sort(([, sizeA], [, sizeB]) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return sizeB - sizeA;
      });

    // Get array of base sizes
    const baseSizesArr = sizeArr.map((size) => {
      const currentSize = themePropValue[size];
      return `${
        currentSize ? parseFloat(currentSize) : parseFloat(size as string)
      }${unit}`;
    });

    const mediaSizesArr: Array<Array<any>> = [baseSizesArr];
    // Create array for media queries
    const mediaQueriesArr: Array<any> = [];

    filteredSettingsBreakpointArr.forEach(([breakpointName, , ...sizeMods]) => {
      if (!media[breakpointName]) {
        return '';
      }

      const newSizeArr = sizeMods.map((sizeMod: T | number, i: number) => {
        const size = sizeArr[i];
        const currentSize = themePropValue[size];
        const currentNumberSize = currentSize
          ? parseFloat(currentSize)
          : parseFloat(size as string);

        // New size could be defined by number or from theme by key
        let sizeReplace;
        if (typeof sizeMod === 'number') {
          sizeReplace = currentNumberSize * sizeMod;
        } else {
          sizeReplace = themePropValue[sizeMod as T];
        }

        let finalNumberSizeReplace = parseFloat(sizeReplace);

        // Check min and max size and replace if needed
        if (minSizeNumber && finalNumberSizeReplace < minSizeNumber) {
          finalNumberSizeReplace = minSizeNumber;
        }
        if (maxSizeNumber && finalNumberSizeReplace > maxSizeNumber) {
          finalNumberSizeReplace = maxSizeNumber;
        }

        // If we don't allow scale - use base size instead of new size
        if (!allowScale && finalNumberSizeReplace > currentNumberSize) {
          finalNumberSizeReplace = currentNumberSize;
        }

        finalNumberSizeReplace = Math.round(finalNumberSizeReplace);

        return `${finalNumberSizeReplace}${unit}`;
      });

      const prevSizeArr = mediaSizesArr[mediaSizesArr.length - 1];
      if (prevSizeArr && prevSizeArr.join() === newSizeArr.join()) {
        return;
      }

      // Remember size array to avoid duplicates
      mediaSizesArr.push(newSizeArr);

      // Create media query with new size and add it to result array
      const newSizeCssPropsAndValues = cssPropArr.map((cssProp) => {
        return `${cssProp}:${newSizeArr.join(' ')};`;
      });

      outputObj.mediaQueries[breakpointName] = newSizeCssPropsAndValues;
      mediaQueriesArr.push(css`
        ${media[breakpointName]} {
          ${newSizeCssPropsAndValues};
        }
      `);
    });

    if (log) {
      console.log('\nMedia Query Generator Size Arr', sizeArr);
      console.log('params', size, settings);
      console.log('mediaSizesArr', mediaSizesArr, '\n');
    }

    const baseSizeCssPropsAndValues = cssPropArr.map((cssProp) => {
      return `${cssProp}:${baseSizesArr.join(' ')};`;
    });

    outputObj.baseStyles = baseSizeCssPropsAndValues;
    const outputCss = css`
      ${baseSizeCssPropsAndValues};
      ${mediaQueriesArr};
    `;
    cache[cacheKey] = [outputCss, outputObj];

    if (outputFormat === 'css') {
      return outputCss;
    } else {
      return outputObj;
    }
  };
};
