import { ToastContainer, ToastOptions, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface IToastOptions {
  className?: string;
  style?: object;
  type?: 'info' | 'success' | 'warning' | 'error' | 'default';
  toastId?: string | number;
  updateId?: string | number;
  progress?: number;
  duration?: number;
  isLoading?: boolean;
  data?: object;
  icon?: React.ReactNode;
  iconTheme?: object;
  ariaProps?: object;
}

/**
 * This function initializes the toast container and styles.
 */
export const init = () => {
  // Connect styles for react-toastify
  // import('react-toastify/dist/ReactToastify.css');

  return {
    ToastContainer: ToastContainer,
  };
};

/**
 * This function transform options to react-toastify options.
 */
const transformOptions = (options: IToastOptions): ToastOptions => {
  return {
    /**
     * An optional css class to set.
     */
    className: options?.className,
    /**
     * An optional inline style to apply.
     */
    style: options?.style,
    /**
     * Set the toast type.
     * `One of: 'info', 'success', 'warning', 'error', 'default'`
     */
    type: options?.type,
    /**
     * Set a custom `toastId`
     */
    toastId: options?.toastId,
    /**
     * Used during update
     */
    updateId: options?.updateId,
    /**
     * Set the percentage for the controlled progress bar. `Value must be between 0 and 1.`
     */
    progress: options?.progress,
    /**
     * Add a delay in ms before the toast appear.
     */
    delay: options?.duration,
    /**
     * Whether or not to display the newest toast on top.
     */
    isLoading: options?.isLoading,
    /**
     * An optional inline style to apply for the toast.
     * */
    data: options?.data,
    /**
     * An optional css class for the toast.
     */
    // icon: options?.icon,
  };
};

/**
 * This function exports a showMessage function that displays a toast message with the given text and
 * options.
 *  @param text: The text to be displayed in the toast message.
 *  @param options: The options to be passed to the toast message.
 *  @example

    import { showMessage } from 'react-toastify-popup-adapter';

    showMessage('Hello World', { type: 'success' });

    Options:
      className?: string;
      style?: object;
      type?: 'info' | 'success' | 'warning' | 'error' | 'default';
      toastId?: string | number;
      updateId?: string | number;
      progress?: number;
      duration?: number;
      isLoading?: boolean;
      data?: object;
      icon?: React.ReactNode;
      iconTheme?: object;
      ariaProps?: object;
 */
export const showMessage = (text: string, options: IToastOptions) => {
  toast(text, transformOptions(options));
};
