// import { addHexAlpha } from 'plugins/styles';
import { common } from './common';
import { ThemeSchema } from '../types';

// Extends from common theme settings
export const dark: ThemeSchema = {
  ...common,
  color: {
    primary: '#1E1F2D',
    primary100: '#E8E8E8',
    primary200: '#C6C6C6',
    primary300: '#8F8F8F',
    primary400: '#6D6D6D',
    primary500: '#3A3A3A',
    primary600: '#282828',
    primary700: '#1E1E1E',
    primary800: '#1E1F2D',
    primary900: '#0F0F0F',

    secondary: '#3F434A',
    secondary100: '#F2F2F2',
    secondary200: '#D9D9D9',
    secondary300: '#A6A6A6',
    secondary400: '#8C8C8C',
    secondary500: '#3F434A',
    secondary600: '#262626',
    secondary700: '#1A1A1A',
    secondary800: '#3F434A',
    secondary900: '#0D0D0D',

    yellow: '#FFD240',
    yellow100: '#FFF8E5',
    yellow200: '#FFEFCB',
    yellow300: '#FFE6B2',
    yellow400: '#FFDD98',
    yellow500: '#FFD240',
    yellow600: '#FFC600',
    yellow700: '#E6AD00',
    yellow800: '#CC9400',
    yellow900: '#B27B00',

    error: '#FD7972',
    error100: '#FFF2F2',
    error200: '#FFD6D6',
    error300: '#FFB9B9',
    error400: '#FF9D9D',
    error500: '#FD7972',
    error600: '#FF5E5E',
    error700: '#FF4242',
    error800: '#FF2626',
    error900: '#FF0A0A',

    pink: '#FD72DE',
    pink100: '#FFF2F7',
    pink200: '#F9DDC5',
    pink300: '#FFB8DF',
    pink400: '#FF9BD3',
    pink500: '#FD72DE',
    pink600: '#FF4EBF',
    pink700: '#FF2A9F',
    pink800: '#FF0680',
    pink900: '#D9005E',

    success: '#49C96D',
    success100: '#E6F5E7',
    success200: '#AEE553',
    success300: '#9EDFB1',
    success400: '#7AD596',
    success500: '#49C96D',
    success600: '#2E9F53',
    success700: '#1F7A3E',
    success800: '#105529',
    success900: '#003214',

    blue: '#22CCE2',
    blue100: '#E6F9FB',
    blue200: '#AEEFF5',
    blue300: '#9ED2DF',
    blue400: '#7ABFD5',
    blue500: '#49ADC9',
    blue600: '#2E8EA0',
    blue700: '#1F6B7A',
    blue800: '#104D55',
    blue900: '#00332F',

    purple: '#B656EB',
    purple100: '#F2E6F9',
    purple200: '#D5AEEF',
    purple300: '#C49ED2',
    purple400: '#A77ABF',
    purple500: '#8A49AD',
    purple600: '#6B2E8E',
    purple700: '#4F1F6B',
    purple800: '#33104D',
    purple900: '#1A0033',

    white: '#FFFFFF',
  },
  boxShadow: {
    primary: '0 0 2px 4px #8F8F8F',
    secondary: '0 0 0 4px #8C8C8C',
  },
  border: {
    primary: `1px solid #0F0F0F`,
  },
  gradient: {
    primary: ['#1E1F2D', '#3A3A3A'],
    secondary: ['#3F434A', '#A6A6A6'],
  },
};

export default dark;
