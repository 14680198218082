import { ThemeBreakpoint } from '../types';

export const mobileS = 320;
export const mobileM = 375;
export const mobileL = 480;
export const tablet = 768;
export const laptopS = 1024;
export const laptop = 1280;
export const laptopL = 1440;
export const desktopS = 1600;
export const desktop = 1920;
export const qhd = 2048;
export const uhd = 2560;
export const main = 1920;

export const breakpoint: ThemeBreakpoint = {
  mobileS,
  mobileM,
  mobileL,
  tablet,
  laptopS,
  laptop,
  laptopL,
  desktop,
  desktopS,
  qhd,
  uhd,
  main,
};
