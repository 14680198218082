import { ThemeSchema } from '../types';

// Extends from light theme
import { light } from './light';

export const dark: ThemeSchema = {
  ...light,
  // changes here...
};

export default dark;
