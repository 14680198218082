// Utils
import {
  createMediaGenerator,
  MediaGeneratorSettings,
} from '@ui/base-theme-auto-size';

export const indentReducePercent: MediaGeneratorSettings<string> = {
  _defaults: {
    mobileS: 0.25,
    mobileL: 0.45,
    tablet: 0.7,
  },
};

export const createIndentGenerator = (cssProp: string) =>
  createMediaGenerator<string>({
    themeProp: 'indent',
    settings: indentReducePercent,
    cssProp,
  });

export const autoCommonMargin = createIndentGenerator('margin');
export const autoCommonMarginTop = createIndentGenerator('margin-top');
export const autoCommonMarginBottom = createIndentGenerator('margin-bottom');
export const autoCommonMarginLeft = createIndentGenerator('margin-left');
export const autoCommonMarginRight = createIndentGenerator('margin-right');

export const autoCommonPadding = createIndentGenerator('padding');
export const autoCommonPaddingTop = createIndentGenerator('padding-top');
export const autoCommonPaddingBottom = createIndentGenerator('padding-bottom');
export const autoCommonPaddingLeft = createIndentGenerator('padding-left');
export const autoCommonPaddingRight = createIndentGenerator('padding-right');
export const autoCommonGap = createIndentGenerator('gap');
export const autoCommonRowGap = createIndentGenerator('row-gap');
export const autoCommonColumnGap = createIndentGenerator('column-gap');

export const indent = {
  margin: autoCommonMargin,
  marginTop: autoCommonMarginTop,
  marginBottom: autoCommonMarginBottom,
  marginLeft: autoCommonMarginLeft,
  marginRight: autoCommonMarginRight,

  padding: autoCommonPadding,
  paddingTop: autoCommonPaddingTop,
  paddingBottom: autoCommonPaddingBottom,
  paddingLeft: autoCommonPaddingLeft,
  paddingRight: autoCommonPaddingRight,

  gap: autoCommonGap,
  rowGap: autoCommonRowGap,
  columnGap: autoCommonColumnGap,
};
