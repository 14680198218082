import { init, RuleSet } from '@libs/react-stylizing-styled-components-adapter';
export * from './StyledComponentsRegistry';

export type { RuleSet };

const {
  styled,
  css,
  useTheme,
  applyTheme,
  keyframes,
  applyCustomCSS,
  createGlobalStyle,
  applyCheckIsStyledComponent,
  applyThemeProvider,
} = init();

export {
  styled,
  css,
  useTheme,
  applyTheme,
  keyframes,
  applyCustomCSS,
  createGlobalStyle,
  applyCheckIsStyledComponent,
  applyThemeProvider,
};
