import { css } from 'styled-components';

// Utils
import { deepSearchKey } from '@tools/ts-deep-search-key';

export interface MergeMediaQueriesParams
  extends Record<string, Array<any> | string> {}

export interface MergeMediaQueriesSettings {
  rootPath?: string;
  subPath?: string;
  mediaPath?: string;
}

export const mergeMediaQueries = (
  p: MergeMediaQueriesParams,
  {
    rootPath = 'autoSize',
    subPath = '',
    mediaPath = 'media',
  }: MergeMediaQueriesSettings = {}
) => {
  return css`
    ${({ theme }) => {
      const autoSizePath = subPath ? `${rootPath}.${subPath}` : rootPath;
      const autoSize = deepSearchKey(theme, autoSizePath);

      const allBaseStyles: Array<string> = [];
      const mediaQueriesObj = Object.entries(p).reduce(
        (acc, [autoSizeName, autoSizeParams]) => {
          const autoSizeFn = deepSearchKey(autoSize, autoSizeName);
          const autoSizeParamsArr = Array.isArray(autoSizeParams)
            ? autoSizeParams
            : [autoSizeParams];
          const [sizeParam, settingsParam = {}] = autoSizeParamsArr;

          if (
            typeof autoSizeFn === 'function' &&
            typeof sizeParam !== 'undefined'
          ) {
            const autoSizeResult = autoSizeFn(sizeParam, {
              ...settingsParam,
              outputFormat: 'obj',
            });

            const { baseStyles = '', mediaQueries = {} } = autoSizeResult || {};
            const baseStylesArr = Array.isArray(baseStyles)
              ? baseStyles
              : [baseStyles];
            allBaseStyles.push(...baseStylesArr);

            Object.entries(mediaQueries).forEach(
              ([breakpointName, mediaQueryStyles]) => {
                const mediaQueryStylesArr = Array.isArray(mediaQueryStyles)
                  ? mediaQueryStyles
                  : [mediaQueryStyles];

                if (acc[breakpointName]) {
                  acc[breakpointName] = [
                    ...acc[breakpointName],
                    ...mediaQueryStylesArr,
                  ];
                } else {
                  acc[breakpointName] = mediaQueryStylesArr;
                }
              }
            );
          }

          return acc;
        },
        []
      );
      const mediaQueries = Object.entries(mediaQueriesObj).map(
        ([breakpointName, mediaQueryStyles]) => {
          const mediaQuery = deepSearchKey(
            theme,
            `${mediaPath}.${breakpointName}`
          );

          if (mediaQuery) {
            return css`
              ${mediaQuery} {
                ${mediaQueryStyles};
              }
            `;
          }

          return '';
        }
      );

      return css`
        ${allBaseStyles};
        ${mediaQueries};
      `;
    }};
  `;
};
