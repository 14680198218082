// Types
import { ThemeSchema } from './types';

// Plugins types
import { ThemeAutoSize } from './plugins/auto-size/types';

// Plugins
import { applyAutoSizeToThemeSchema } from '@ui/base-theme-auto-size';
import { autoSize } from './plugins/auto-size';

// Theme schemas
import lightTheme from './schemas/light';
import darkTheme from './schemas/dark';

// Export plain theme schemas
export { lightTheme, darkTheme };

export interface ThemeSchemaWithPlugins extends ThemeSchema {
  autoSize: ThemeAutoSize;
}

// Export theme schemas with plugins
export const lightThemeWithPlugins = applyAutoSizeToThemeSchema<
  ThemeSchema,
  ThemeAutoSize
>({
  schema: lightTheme,
  config: autoSize,
});

export const darkThemeWithPlugins = applyAutoSizeToThemeSchema<
  ThemeSchema,
  ThemeAutoSize
>({
  schema: darkTheme,
  config: autoSize,
});

export { breakpoint } from './schemas/breakpoint';
