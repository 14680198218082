// Export utils
export { createMediaGenerator } from './utils/createMediaGenerator';
export { mergeMediaQueries } from './utils/mergeMediaQueries';

// Export types
export type {
  MediaGeneratorSettings,
  CreateMediaGeneratorParams,
  MediaGeneratorOutputObject,
  MediaGeneratorParams,
} from './utils/createMediaGenerator';

export type { MergeMediaQueriesParams } from './utils/mergeMediaQueries';

// Utils
export { applyAutoSizeToThemeSchema } from './utils/applyAutoSizeToThemeSchema';
