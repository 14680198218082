export const palette = {
  primary: '#F8BEC4',
  secondary: '#F8BEC4',
  background: '#0f172a',
  text: '#02273b',
  hintsText: '#02273b',
  placeholder: '#7e8f98',
  info: '#4f46e5',
  error: '#be123c',
  warning: '#eab308',
  success: '#16a34a',
};
