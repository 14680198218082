import {
  ThemeBorderRadius,
  ThemeFontSize,
  ThemeFontWeight,
  ThemeHeight,
  ThemeIndent,
  ThemeMaxWidth,
  ThemeMediaQuery,
  ThemeOpacity,
  ThemeTransition,
  ThemeTransitionDuration,
} from '../types';

// Breakpoints
import { breakpoint } from './breakpoint';

export const borderRadius: ThemeBorderRadius = {
  main: '8px',
  secondary: '6px',
  round: '50%',
  xs: '6px',
  s: '8px',
  sm: '12px',
  m: '15px',
  md: '16px',
  l: '20px',
  xl: '25px',
  xxl: '35px',
};

export const fontSize: ThemeFontSize = {
  xs: '12px',
  sm: '14px',
  main: '16px',
  lg: '18px',
  xl: '20px',
  '2xl': '24px',
  '3xl': '30px',
  '4xl': '36px',
  '5xl': '48px',
  '6xl': '60px',
  '7xl': '72px',
  '8xl': '96px',
  '9xl': '128px',
};

export const fontWeight: ThemeFontWeight = {
  thin: 100,
  normal: 400,
  bold: 500,
  bolder: 600,
  fat: 800,
};

export const height: ThemeHeight = {
  xs: '16px',
  s: '20px',
  sm: '24px',
  m: '32px',
  md: '42px',
  lg: '52px',
  xl: '60px',
  xxl: '70px',
};

export const indent: ThemeIndent = {
  xxs: '4px',
  xs: '8px',
  s: '12px',
  sm: '14px',
  m: '16px',
  md: '17px',
  mdl: '20px',
  l: '24px',
  xl: '29px',
  xls: '32px',
  xll: '45px',
  xxl: '48px',
  '2xxl': '50px',
  '3xxl': '60px',
};

export const maxWidth: ThemeMaxWidth = {
  main: '1920px',
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '480px',
  tablet: '768px',
  laptopS: '1024px',
  laptop: '1280px',
  laptopL: '1440px',
  desktop: '1920px',
  '4k': '2560px',
};

export const media: ThemeMediaQuery = {
  mobileS: `@media (max-width: ${breakpoint.mobileS}px)`,
  mobileM: `@media (max-width: ${breakpoint.mobileM}px)`,
  mobileL: `@media (max-width: ${breakpoint.mobileL}px)`,
  tablet: `@media (max-width: ${breakpoint.tablet}px)`,
  laptopS: `@media (max-width: ${breakpoint.laptopS}px)`,
  laptop: `@media (max-width: ${breakpoint.laptop}px)`,
  laptopL: `@media (max-width: ${breakpoint.laptopL}px)`,
  desktop: `@media (max-width: ${breakpoint.desktop}px)`,
  desktopS: `@media (max-width: ${breakpoint.desktopS}px)`,
  qhd: `@media (max-width: ${breakpoint.qhd}px)`,
  uhd: `@media (max-width: ${breakpoint.uhd}px)`,
  main: `@media (max-width: ${breakpoint.main}px)`,
};

export const opacity: ThemeOpacity = {
  xs: 0.9,
  s: 0.8,
  sm: 0.7,
  m: 0.56,
  md: 0.4,
  l: 0.2,
  xl: 0.12,
  xxl: 0.01,
};

export const transitionDuration: ThemeTransitionDuration = {
  short: '300ms',
  medium: '500ms',
  long: '1000ms',
};

export const transition: ThemeTransition = {
  short: `all ${transitionDuration.short} ease-in-out`,
  medium: `all ${transitionDuration.medium} ease-in-out`,
  long: `all ${transitionDuration.long} ease-in-out`,
};

export const common = {
  borderRadius,
  breakpoint,
  fontSize,
  fontWeight,
  height,
  indent,
  maxWidth,
  media,
  opacity,
  transition,
  transitionDuration,
};
