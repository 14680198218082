import { css } from 'styled-components';

export const globalStylesCss = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  button,
  input,
  textarea {
    all: unset;
  }

  *,
  button,
  input,
  textarea,
  select {
    box-sizing: border-box;
  }
`;
