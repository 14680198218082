'use client';

import React from 'react';

// Styles
import { GlobalStyles } from '../GlobalStyles';

// UI
import { init } from '@ui/base-popup-messages';
import { ThemeProvider } from 'styled-components';
import { lightThemeWithPlugins } from 'shared/theme';

// Styled components SSR
import { StyledComponentsRegistry } from '@ui/react-stylizing-components';

// React Query
import { QueryClientProvider, queryClient } from 'shared/libs/rest';

// Connect popup messages to the root layout
const {
  ToastContainer
} = init();

// Types
export interface ClientRootLayoutProps {
  children: React.ReactNode;
}
export const ClientRootLayout = ({
  children
}: ClientRootLayoutProps) => {
  return <QueryClientProvider client={queryClient}>
      <StyledComponentsRegistry>
        <ThemeProvider theme={lightThemeWithPlugins}>
          <GlobalStyles />
          {children}
          <ToastContainer />
        </ThemeProvider>
      </StyledComponentsRegistry>
    </QueryClientProvider>;
};