import { createGlobalStyle, css } from 'styled-components';
import { globalStylesCss } from '@ui/base-global-styles';
export const GlobalStyles = createGlobalStyle`
  ${globalStylesCss};

  html {
    font-size: 15px;
    ${({
  theme
}) => css`
      color: ${theme['color'].dark500};
    `};
  }

`;