import {
  ThemeBorderRadius,
  ThemeFontSize,
  ThemeFontWeight,
  ThemeHeight,
  ThemeMaxWidth,
  ThemeMediaQuery,
  ThemeOpacity,
  ThemeTransition,
  ThemeTransitionDuration,
} from '../types';

// Breakpoints
import { breakpoint } from './breakpoint';

export const borderRadius: ThemeBorderRadius = {
  round: '50%',
  xs: '5px',
  s: '10px',
  m: '14px',
  l: '20px',
};

export const fontSize: ThemeFontSize = {
  xs: '12px',
  s: '14px',
  m: '15px',
  h6: '18px',
  h5: '20px',
  h4: '28px',
  h3: '36px',
  h2: '48px',
  h1: '56px',
};

export const fontWeight: ThemeFontWeight = {
  thin: 100,
  normal: 400,
  medium: 500,
  bold: 600,
  bolder: 800,
};

export const height: ThemeHeight = {
  xs: '16px',
  s: '24px',
  m: '32px',
  l: '40px',
};
export const maxWidth: ThemeMaxWidth = {
  main: '1920px',
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '480px',
  tablet: '768px',
  laptopS: '1024px',
  laptop: '1280px',
  laptopL: '1440px',
  desktop: '1920px',
  '4k': '2560px',
};

export const media: ThemeMediaQuery = {
  mobileS: `@media (max-width: ${breakpoint.mobileS}px)`,
  mobileM: `@media (max-width: ${breakpoint.mobileM}px)`,
  mobileL: `@media (max-width: ${breakpoint.mobileL}px)`,
  tablet: `@media (max-width: ${breakpoint.tablet}px)`,
  laptopS: `@media (max-width: ${breakpoint.laptopS}px)`,
  laptop: `@media (max-width: ${breakpoint.laptop}px)`,
  laptopL: `@media (max-width: ${breakpoint.laptopL}px)`,
  desktop: `@media (max-width: ${breakpoint.desktop}px)`,
  desktopS: `@media (max-width: ${breakpoint.desktopS}px)`,
  qhd: `@media (max-width: ${breakpoint.qhd}px)`,
  uhd: `@media (max-width: ${breakpoint.uhd}px)`,
  main: `@media (max-width: ${breakpoint.main}px)`,
};

export const opacity: ThemeOpacity = {
  xs: 0.9,
  s: 0.8,
  sm: 0.7,
  m: 0.56,
  md: 0.4,
  l: 0.2,
  xl: 0.12,
  xxl: 0.01,
};

export const transitionDuration: ThemeTransitionDuration = {
  short: '300ms',
  medium: '500ms',
  long: '1000ms',
};

export const transition: ThemeTransition = {
  short: `all ${transitionDuration.short} ease-in-out`,
  medium: `all ${transitionDuration.medium} ease-in-out`,
  long: `all ${transitionDuration.long} ease-in-out`,
};

export const common = {
  borderRadius,
  breakpoint,
  fontSize,
  fontWeight,
  height,
  maxWidth,
  media,
  opacity,
  transition,
  transitionDuration,
};
